import React from "react";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import { Text } from "../../../core/constants/strings";

const Footer = () => {
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${images.footerBackground})` }}>
      <div className={styles.uppper}>
        <img src={images.footerImageOne} />
        <div className={styles.title}>{Text.footer.title}</div>
        <img src={images.footerImageTwo} />
      </div>
      <div className={styles.middle}>
        <img src={images.footerImageThree} />
        <div className={styles.logoContent}>
          <div className={styles.logoTitle}>{Text.footer.appName}</div>
          <img className={styles.logoImage} src={images.logoNMCP} />
        </div>
      </div>
      <div className={styles.lower}>
        <div className={styles.navi}>
          <img className={styles.icon} src={images.worldImage} />
          <div>{Text.footer.webSite}</div>
        </div>
        <div className={styles.navi}>
          <img className={styles.icon} src={images.phoneImage} />
          <div>{Text.footer.phoneNumber}</div>
        </div>
        <div className={styles.navi}>
          <img className={styles.icon} src={images.mailImage} />
          <div>{Text.footer.eMail}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
