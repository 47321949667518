import React from "react";
import Welcome from "../../../desktop/blocks/Welcome/Welcome";
import Started from "../../../desktop/blocks/Started/Started";
import Carousel from "../../../desktop/blocks/Carousel/Carousel";
import HowItWorks from "../../../desktop/blocks/HowItWorks/HowItWorks";
import Download from "../../../desktop/blocks/Download/Download";
import Footer from "../../../desktop/blocks/Footer/Footer";
import OurTeamCarousel from "../../../desktop/blocks/OurTeamCarousel/OurTeamCarousel";

const FullPage = () => {

  return (
    <>
      <Welcome />
      <Started />
      <Carousel />
      <HowItWorks />
      <OurTeamCarousel />
      <Download />
      <Footer />
    </>
  );
};

export default FullPage;
